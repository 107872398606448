import React, {  } from 'react';
import './Home.css';
import { motion } from 'framer-motion';


const textVariants = {
  initial: {
    x: 800,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 8,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};

const buttonVariants = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 4,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};

const sliderImage = {
  initial: {
    x: 0,
  },
  animate: {
    x: [0, -2000, 0], // Moves to the left and then resets
    transition: {
      duration: 6,
      ease: "linear",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};


const Home = () => {
  return (
    <div>
      <section className='section1'>
        <div className='Wrapper'>
          <motion.div className="textContainer" variants={textVariants} initial="initial" animate="animate">
            <motion.h1 variants={textVariants}>Unilock Your</motion.h1>
            <motion.h1 variants={textVariants}>Financial Freedom</motion.h1>
            <motion.h1 variants={textVariants}>Move To The Moon</motion.h1>
            <motion.div className='buttonContainer'>
              <motion.button variants={buttonVariants} initial="initial" animate="animate">Freedom Best choice For You</motion.button>
              <motion.button variants={buttonVariants} initial="initial" animate="animate">Lif Time Money</motion.button>
            </motion.div>
          </motion.div>
          <motion.div className='immagContainer' variants={sliderImage} initial="initial" animate="animate">
            <img src='/tranding.png'alt=''/>
          
          </motion.div>
        </div> 
      </section>
    </div>
  );
};

export default Home;

