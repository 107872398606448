import React, { useEffect, useState } from "react";
import axios from "axios";
import { auth } from "../../../firebase";
import "./AdminDashboard.css"; // Import your CSS file for styling
import withAuth from "../../ProtectionHOCs/withAuth";

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: "", password: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.get("https://freedombot.online/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  const createUser = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        "https://freedombot.online/user/create-user",
        newUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const createdUser = response.data.user;
      setUsers((prevUsers) => [...prevUsers, createdUser]); // Add new user to the state array
      setNewUser({ email: "", password: "" }); // Reset new user form
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  const deleteUser = async (userId) => {
    const isSure = window.confirm("Are you sure you want to delete this user?");
    if (isSure) {
      try {
        const token = await auth.currentUser.getIdToken();
        await axios.delete(`https://freedombot.online/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.uid !== userId)
        ); // Remove the deleted user from the state array
      } catch (error) {
        console.error("Error deleting user: ", error);
      }
    }
  };

  const giveUserAccess = async (userId) => {
    const isSure = window.confirm(
      "Are you sure you want to give this user access?"
    );
    if (isSure) {
      try {
        const token = await auth.currentUser.getIdToken();
        await axios.put(
          `https://freedombot.online/user/give-user-access/${userId}`,{},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.uid === userId
              ? {
                  ...user,
                  customClaims: { ...user.customClaims, hasAccess: true },
                }
              : user
          )
        );
      } catch (error) {
        console.error("Error giving user access: ", error);
      }
    }
  };

  const removeUserAccess = async (userId) => {
    const isSure = window.confirm(
      "Are you sure you want to remove this user access?"
    );
    if (isSure) {
      try {
        const token = await auth.currentUser.getIdToken();
        await axios.put(
          `https://freedombot.online/user/remove-user-access/${userId}`,{},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.uid === userId
              ? {
                  ...user,
                  customClaims: { ...user.customClaims, hasAccess: false },
                }
              : user
          )
        );
      } catch (error) {
        console.error("Error deleting user: ", error);
      }
    }
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <button onClick={() => setIsModalOpen(true)}>Create New User</button>

      <table className="users-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Paid</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.needsToPay ? "No" : "Yes"}</td>
              <td>
                <button onClick={() => deleteUser(user.uid)}>Delete</button>
                {user?.customClaims?.hasAccess && (
                  <button onClick={() => removeUserAccess(user.uid)}>
                    Remove access
                  </button>
                )}
                {!user?.customClaims?.hasAccess && (
                  <button onClick={() => giveUserAccess(user.uid)}>
                    Give acces
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Create New User</h2>
            <input
              type="email"
              placeholder="Email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
            />
            <input
              type="password"
              placeholder="Password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
            />
            <button onClick={createUser}>Create User</button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default withAuth(AdminDashboard);
