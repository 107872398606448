import React, { useEffect, useState } from "react";
import "./checkout.css";
import { toast } from "react-toastify";
import { auth } from "../../../firebase";
import withAuth from "../../ProtectionHOCs/withAuth";

const CheckoutPage = () => {
  const [packageType,setPackageType]=useState("monthly");
  const [coinType,setCoinType]=useState("USDT.TRC20");
  const getPaymentUrl = () => {
    try {
      auth.currentUser.getIdToken().then(async (token) => {
        const response = await fetch(
          `https://freedombot.onlne/payment/create-checkout`,
          {
            method: "POST",
            body: JSON.stringify({ 
              packageType,
              coinType
          }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => response.json());

        if (response.checkout_url) {
          window.open(response.checkout_url, "_blank");
        } 
      });
      } catch (error) {
      console.error("Error getting payment url", error);
    }
  };

  useEffect(() => {
    toast.info("You should first pay to access the bot");
  }, []);
  

  return (
    <div className="container">
      <div className="checkout">
        <h1>Checkout Page</h1>
        <div>
          <label>
            Selecy Your desired package:
            <select value={packageType} onChange={(e) => setPackageType(e.target.value )}>
              <option value="weekly">Weekly - 25 USDT</option>
              <option value="monthly">Monthly - 50 USDT</option>
              <option value="yearly">Yearly - 450 USDT</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Currency:
            <select value={coinType} onChange={(e) => setCoinType( e.target.value)}>
              <option value="USDT.TRC20">USDT.TRC20</option>
              <option value="BTC.BEP20">BTC.BEP20</option>
            </select>
          </label>
        </div>
        <button onClick={getPaymentUrl}>Process Payment</button>
      </div>
    </div>
  );
};


export default withAuth(CheckoutPage);
