const io = require("socket.io-client");

const  baseURL= "https://freedombot.online";

const socket = io.connect(baseURL,{
    autoConnect: false
});


const joinRoom = (roomID) => {
    socket.emit("join_room", roomID);
};

module.exports={
    socket,
    joinRoom,
}