import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { joinRoom, socket } from "../../config/socketIo";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userPaymentStatus, setUserPaymentStatus] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async(currentUser) => {
      if (currentUser) {
        const idTokenResult = await currentUser.getIdTokenResult(true);
        setUser({...currentUser,hasAccess:idTokenResult?.claims?.hasAccess});
        checkUserPaymentStatus(currentUser.uid);
      }
      else{
        setUserPaymentStatus({})
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user?.uid) {
      console.log(`User`,user)
      socket.connect();
      joinRoom(user?.email);

      socket.on("paymentMade",()=>{
        toast.success("Payment recorded");
        setTimeout(()=>{

          window.location.pathname="/trade"
        },2000)
      })
    }

    socket.on("disconnect", () => {
      socket.disconnect();
    });
    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, [user]);

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      checkUserPaymentStatus();
    } catch (error) {
      console.error("Failed to sign in", error);
    }
  };

  const checkUserPaymentStatus = () => {
    try {
      auth.currentUser.getIdToken().then(async (token) => {
        const response = await fetch(
          `https://freedombot.online/payment/payment-status`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => response.json());
        setUserPaymentStatus(response);
      });
    } catch (error) {
      console.error("Error checking payment status", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, userPaymentStatus, signIn, checkUserPaymentStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};
