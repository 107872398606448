import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminDashboard from "./components/pages/Admin/AdminDashboard";
import UserDashboard from "./components/pages/UserDashboard";
import CheckoutPage from "./components/pages/checkout/checkoutPage";
import Contact from "./components/pages/contact";
import Navbar from "./components/Navbar/navbar";
import Home from "./components/pages/home/Home";
import Trade from "./components/pages/Trade";
import Login from "./components/pages/Login";
import Footer from "./components/Foot/footer";
import { useAuth } from "./components/pages/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AppRoutes() {
  const { user } = useAuth();

  return (
    <Router>
      <ToastContainer style={{ zIndex: 9999 }} />
      <div className="App">
        <Navbar />
        <Routes>
          {!user && <Route path="/" element={<Home />} />}
          <Route path="/trade" element={<Trade />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/user" element={<UserDashboard />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default AppRoutes;
